<template>
    <div class="js-entry o-entry c-html-entry">
        <iframe class="c-html-entry__iframe" v-if="entryData.Content.Index" :src="$xp.settings.baseUrl + 'xpedeo/' + entryData.Content.Index"></iframe>
        <iframe class="c-html-entry__iframe" v-else-if="entryData.Content.Url" :src="(entryData.Content.Url.indexOf('http://') === 0 || entryData.Content.Url.indexOf('https://') === 0) ? entryData.Content.Url : $xp.settings.baseUrl +'pda/'+ entryData.Content.Url"></iframe>
    </div>
</template>

<script>
import { handleExternalUrl } from '@/util/router-guards'
import entryMixin from '../../mixins/entry'

export default {
  mixins: [entryMixin],
  data () {
    return {}
  },
  mounted () {
    console.log('htmlentry Mounted', this.entryData)
    window.addEventListener('message', this.onMessage, false)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.onMessage, false)
  },
  methods: {
    onMessage (e) {
      let method = ''
      if (typeof e.data === 'string') {
        method = e.data
      } else if (typeof e.data === 'object') {
        method = e.data.method
      } else {
        throw Error('event data must be a string or an object contains method property as string')
      }
      console.log('HtmlEntry.onMessage:', e.data, ', method', method)
      switch (method) {
        case 'touchstart':
        case 'pointerdown':
          this.onTouchStart()
          break
        case 'touchend':
        case 'pointerup':
          this.onTouchEnd()
          break
        case 'back':
          this.$xp.history.back()
          break
        case 'showPage':
          this.$router.push('/page/' + e.data.pageID)
          break
        case 'openLink':
          handleExternalUrl(e.data.href)
          break
      }
    },
    onTouchStart () {
      const e = new Event('pointerdown', { bubbles: true })
      this.$el.dispatchEvent(e)
    },
    onTouchEnd () {
      const e = new Event('pointerup', { bubbles: true })
      this.$el.dispatchEvent(e)
    }
  }
}
</script>
